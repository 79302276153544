import { AxiosError } from "axios";
import customAxios from "config";

export const getBonzahProducts = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.get<any>(
      `/api/bonza/insurance-optionsdfsf/`,
      {
        params: payload, // Ensures payload is sent as query parameters
      }
    );
    return { data: response.data };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during fetching Bonzah Products.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
